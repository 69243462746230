import { Action, Selector, State, StateContext } from '@ngxs/store';
import { JwtStateModel } from '../models/JwtStateModel';
import { Injectable } from '@angular/core';
import { JwtGetInvitations, JwtResendInvitation, JwtRevokeInvitation } from '../actions/jwt.action';
import { JwtInviteService } from '../../../api/services/jwt-invite.service';
import { tap } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@State<JwtStateModel>({
  name: 'Jwt',
})
@Injectable()
export class JwtState {
  @Selector()
  static getInvitations(state: JwtStateModel) {
    return state.invitations;
  }
  constructor(
    private readonly jwtInviteService: JwtInviteService,
    private readonly toastr: ToastrService,
  ) {}

  @Action(JwtGetInvitations)
  getJwtInvitations({ patchState }: StateContext<JwtStateModel>, { payload }: JwtGetInvitations) {
    return this.jwtInviteService.getInvitations(payload).pipe(
      tap({
        next: (result) => {
          patchState({
            invitations: result.body,
          });
        },
        error: (err) => {
          throw err.error;
        },
      }),
    );
  }

  @Action(JwtRevokeInvitation)
  jwtRevokeInvitation(
    { patchState, getState }: StateContext<JwtStateModel>,
    { payload }: JwtRevokeInvitation,
  ) {
    const state = getState();
    return this.jwtInviteService.revokeInvitation(payload.invitationId).pipe(
      tap({
        next: () => {
          this.toastr.success('Invitation revoked');

          patchState({
            invitations: state.invitations.filter(
              (invitation) => invitation._id !== payload.invitationId,
            ),
          });
        },
        error: (err) => {
          this.toastr.error('Error revoking invitation');
          throw err.error;
        },
      }),
    );
  }

  @Action(JwtResendInvitation)
  jwtResendInvitation(
    { patchState, getState }: StateContext<JwtStateModel>,
    { payload }: JwtResendInvitation,
  ) {
    const state = getState();
    return this.jwtInviteService.resendInvitation(payload.invitationId).pipe(
      tap({
        next: () => {
          this.toastr.success('Invitation resent');
        },
        error: (err) => {
          this.toastr.error('Error revoking invitation');
          throw err.error;
        },
      }),
    );
  }
}
