export class JwtGetInvitations {
  static readonly type = '[Jwt] GetInvitations';
  constructor(
    public payload?: {
      search?: string;
    },
  ) {}
}

export class JwtResendInvitation {
  static readonly type = '[Jwt] ResendInvitation';
  constructor(public payload: { invitationId: string }) {}
}

export class JwtRevokeInvitation {
  static readonly type = '[Jwt] RevokeInvitation';
  constructor(public payload: { invitationId: string }) {}
}
